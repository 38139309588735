export const TOKEN_KEY = "@buylitics-token-access-key-lead4v";
export const CONTROL_ID = "@buylitics-token-control-id-lead4v";
export const WAIT_CODE = "@buylitics-wait-code-lead4v";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  if(typeof token !== 'undefined' && token !== false && token !== 'null'){
    localStorage.setItem(TOKEN_KEY, token);
    return true;
  }else{
    clear()
    return false;
  }
};

export const setToken = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const clear = () => {
    localStorage.removeItem(WAIT_CODE);
    localStorage.removeItem(CONTROL_ID);
    localStorage.removeItem(TOKEN_KEY);
};

export const setControlId = control_id => {
    localStorage.setItem(CONTROL_ID, control_id);
}
export const getControlId = () => {
    if(localStorage.getItem(CONTROL_ID) !== null){
        return localStorage.getItem(CONTROL_ID)
    }else{
        return null
    }
}

export const setWaitCode = () => {
    localStorage.setItem(WAIT_CODE, true);
}
export const getWaitCode = () => {
    console.log(localStorage.getItem(WAIT_CODE))
    if(localStorage.getItem(WAIT_CODE) !== null){
        return true
    }else{
        return false
    }
}
export const removeWaitCode = () => {
    localStorage.removeItem(WAIT_CODE);
}

export const logout = () => {
    clear();
    window.location.reload()
};