import React, { Component } from 'react';

class Page404 extends Component {
    render() {
        return (
            <div>
                Pagina não encontrada
            </div>
        );
    }
}

export default Page404;