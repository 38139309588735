import React, { Component } from 'react';

import api from '../../Services/Api';

import {isAuthenticated, getToken, setToken, logout, setWaitCode, getWaitCode, removeWaitCode, setControlId, getControlId} from '../../Auth/AuthConfig'

class Lead4v extends Component{

    constructor(props){
        super(props)

        document.title = "Lead - Buylitics"

        this.state = {
            idLead: document.location.hash.replace('#',''),
            waitCode: getWaitCode(),
            formRequestAuth: {
                phone: null,
                email: null,
                accepted_terms: false
            },
            formAuth: {
                control_id: getControlId(),
                code: null
            },
            lead: {
                lead2v: ['Carregando...'],
                name: 'Carregando...',
                email: 'Carregando...',
                phone: 'Carregando...',
                company: 'Carregando...',
                business: 'Carregando...',
                approach: 'Carregando...',
                data_created_in: '00/00/0000',
                hour_created_in: '00:00',
                score: '-',
                prime_time_initial: '00:00',
                prime_time_final: '00:00',
                danger_time_initial: '00:00',
                danger_time_final: '00:00',
            }
        }
        this.formSubmitRequest = this.formSubmitRequest.bind(this)
        this.formSubmitAuth = this.formSubmitAuth.bind(this)
        this.delogarLead4v = this.delogarLead4v.bind(this)
    }

    /* TOKEN */
    /*
    isAuthenticated(){
        return localStorage.getItem('@buylitics-token-access-key-lead4v') !== null
    }
    */

    /*
    getToken(){
        return localStorage.getItem('@buylitics-token-access-key-lead4v')
    }
    */
    
    /*
    setToken(token){
        localStorage.setItem('@buylitics-token-access-key-lead4v', token);
        return true;
    }
    */

    /*
    removeToken(){
        localStorage.removeItem('@buylitics-token-access-key-lead4v');
        window.location.reload();
    }
    */

    /* WAIT CODE */
    /*
    setWaitCode(){
        localStorage.setItem('@buylitics-wait-code-lead4v', 'wait');
    }
    getWaitCode(){
        return localStorage.getItem('@buylitics-wait-code-lead4v')
    }
    removeWaitCode(){
        localStorage.removeItem('@buylitics-wait-code-lead4v');
    }
    */

    async loadLead(){
        const response = await api.get("/lead4v/" + this.state.idLead + '/' + getToken());
        if(response.data.success){
            this.setState({
                lead: response.data.lead
            })
        }
        console.log(response);
    }

    async formSubmitRequest(e){
        e.preventDefault()
        const data = this.state.formRequestAuth;
        try{
            const response = await api.post("/lead4v/request_auth/" + this.state.idLead, data);
            if(response.data.success === true){
                /*
                this.setState({
                    waitCode: true,
                    formAuth: {...this.state.formAuth, control_id:  response.data.control_id}
                })
                */
                setWaitCode()
                setControlId(response.data.control_id)
                window.location.reload();
            }else{
                console.log('error')
            }
        }catch(err){
            console.log(err)
        }
        //console.log('logar');
        //this.setToken('teste')
        //window.location.reload();
    }

    async formSubmitAuth(e){
        e.preventDefault()
        const data = this.state.formAuth;
        try{
            const response = await api.post("/lead4v/auth/" + this.state.idLead, data);
            if(response.data.success === true){
                setToken(response.data.token)
                removeWaitCode()
                window.location.reload();
            }else{
                alert('Olá, seu codigo de autenticação é invalido ou ja expirou, por favor clique em Solicitar Novamente Autenticação!')
            }
        }catch(err){
            console.log(err)
        }
    }

    delogarLead4v(e){
        e.preventDefault();
        logout();
    }

    componentDidMount(){
        if(isAuthenticated()){
            window.jQuery || document.write('<script src="/docs/4.3/assets/js/vendor/jquery-slim.min.js"></script>')
            this.loadLead();
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    (isAuthenticated())?(
                        <React.Fragment>
                            <div className="app">
                                <header style={{backgroundImage: 'url(img/bg_sample.jpg)'}}>
                                    {
                                        (this.state.lead.logo_business)?(
                                            <a href="/" onClick={e => e.preventDefault()}>
                                                <img src="img/logo_sample2.png" alt="Exemplo de logo"/>
                                            </a>
                                        ):(false)
                                    }
                                </header>
                                
                                <main>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <img className="logo" src="img/logo2.png" alt="Buylitics"/>
                                        </div>
                                        <div className="col-md-6 col-sm-6 text-right">
                                            <img className="logo" src="img/logo-lead40.png" alt="Lead 4.0"/>
                                        </div>
                                        <span className="divider"></span>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-12">
                                            <h1>
                                                {this.state.lead.name}
                                            </h1>
                                            <div className="icons">
                                                <h5>
                                                    <i className="icofont-envelope"></i> {this.state.lead.email}
                                                </h5>
                                                <span>|</span>
                                                <h5>
                                                    <i className="icofont-phone"></i> {this.state.lead.phone}
                                                </h5>
                                                <span>|</span>
                                                <h5>
                                                    <a href={'https://api.whatsapp.com/send?phone='+ this.state.lead.phone +'&text=Ola'} target="_blank"><i className="icofont-whatsapp f16"></i></a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="emp">Empreendimento de interesse: <span>{this.state.lead.business}</span></h6>
                                            <h6 className="emp">Incorporadora: <span>{this.state.lead.company}</span></h6>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="more">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <hr/>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <h3>{this.state.lead.score}</h3>
                                                    <h6>Leadscore</h6>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <h4>
                                                        das {this.state.lead.prime_time_initial}
                                                        <br/>
                                                        até {this.state.lead.prime_time_final}
                                                    </h4>
                                                    <h6>Primetime</h6>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <h4>
                                                        das {this.state.lead.danger_time_initial}
                                                        <br/>
                                                        até {this.state.lead.danger_time_final}
                                                    </h4>
                                                    <h6>Dangertime</h6>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <h4>
                                                        {this.state.lead.data_created_in}
                                                        <br/>
                                                        {this.state.lead.hour_created_in}
                                                    </h4>
                                                    <h6>Data de criação</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row more">
                                        <div className="more">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <hr/>
                                                    <h5>Como abordar o lead:</h5>
                                                    <p>{this.state.lead.approach}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </main>
                            </div>
                            <div className="app bg-white">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <h5>Descrição do lead:</h5>
                                        {
                                            this.state.lead.lead2v.map((data, i) => (
                                                <p key={i}>
                                                    {data.text}
                                                </p>
                                            ))
                                        }
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <button className="btn btn-danger float-right" onClick={this.delogarLead4v}>Sair do Lead 4.0</button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.waitCode === true)?(
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="app bg-white col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                                <form onSubmit={this.formSubmitAuth}>
                                                    <div className="form-group">
                                                        Seu codigo de autorização foi enviado com sucesso para seu e-mail e celular, informe assim que receber para acessar as informações do Lead 4.0!
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Codigo de Autenticação</label>
                                                        <input type="number" onChange={e => this.setState({ formAuth: {...this.state.formAuth, code:  e.target.value}})} defaultValue={this.state.formAuth.code} className="form-control" placeholder="Informe o codigo de autenticação" required/>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <button type="submit" id="request_button_submit" className="btn btn-fw button-login btn-rounded mt20 pull-right btn-shadow px-5">
                                                            <i className="fa fa-lock"></i> Enviar Codigo de Autenticação
                                                        </button>
                                                    </div>
                                                </form>
                                                <div className="text-center">
                                                    <p>
                                                        Houve alguma problema em solicitar seu codigo de autenticação?
                                                    </p>
                                                    <button className="btn btn-link" onClick={this.delogarLead4v}>Solicitar Novamente Autenticação</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="app bg-white col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                                <form onSubmit={this.formSubmitRequest}>
                                                    <div className="form-group">
                                                        <label>E-mail</label>
                                                        <input type="email" onChange={e => this.setState({ formRequestAuth: {...this.state.formRequestAuth, email:  e.target.value}})} defaultValue={this.state.formRequestAuth.email} className="form-control" placeholder="Informe seu e-mail" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Celular (DDD + Numero)</label>
                                                        <input type="number" onChange={e => this.setState({ formRequestAuth: {...this.state.formRequestAuth, phone:  e.target.value}})} defaultValue={this.state.formRequestAuth.phone} className="form-control" placeholder="Informe seu celular" required/>
                                                    </div>
                                                    <div className="form-group text-center pb-3">
                                                        <input type="checkbox" onChange={e => this.setState({ formRequestAuth: {...this.state.formRequestAuth, accepted_terms:  e.target.value}})} className="float-left mr-1 mt-1" required/>
                                                        <spam className="float-left" style={{fontSize: 'medium'}}>Eu li e aceito os Termo de Uso e Privacidade.</spam>
                                                    </div>
                                                    <div className="form-group text-center mt-2">
                                                        <button type="submit" id="request_button_submit" className="btn btn-fw button-login btn-rounded mt20 pull-right btn-shadow px-5">
                                                            <i className="fa fa-lock"></i> Solicitar Codigo De Acesso
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

export default Lead4v;