import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import Lead4v from './components/Pages/Leads/Lead4v';
import Page404 from './components/Pages/Errors/Page404';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/lead' component={Lead4v}/>
            <Route component={Page404}/>
        </Switch>
    </BrowserRouter>
);

export default Routes;