import axios from "axios";

import {logout} from '../Auth/AuthConfig';
import {urlBase, versionBase} from './Config';

const api = axios.create({
    baseURL: urlBase + versionBase
});

api.interceptors.response.use(
    (response) => {
        const data = response.data;
        if(typeof data !== 'undefined'){
            return response;
        }
    },
    (err) => {
        const error = err.response;
        if(error.status === 401){
            logout();
        }
    }
);

export default api;